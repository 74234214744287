<template>
  <div v-if="bootstrapStore.isInit" class="bg-slate-200">
    <div
      class="relative border-slate-300 border mx-auto max-w-2xl flex flex-col w-full items-stretch border-b border-sand"
    >
      <div
        class="flex items-center justify-between border-b border-gray-300 shadow border-marble h-16 bg-white"
      >
        <div class="flex flex-row mx-4" >
         
          <span class="text-lg text-gray-900 font-semibold">PhotoWish </span>
          
        </div>

        <div class="flex items-center mx-2 gap-x-2 relative flex-shrink-0">
         
        </div>
      </div>
      <div class="min-h-screen pb-16">
        <slot />
      </div>  
    </div>
  </div>  
    <div v-else>
    <div class="flex flex-col h-screen justify-between">
      <div class="flex items-center justify-center flex-grow">
        <div class="text-2xl">Loading...</div>
      </div>
      <div class="flex justify-center pb-4">
        <span class="text-gray-500 text-sm">version 2.1.2</span>
      </div>
    </div>
  </div>
  <GlobalLoading />
</template>

<script setup>
const bootstrapStore = useBootstrapStore();
bootstrapStore.init();
</script>
